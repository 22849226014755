import { useNavigate } from "react-router";
import React, { createElement, useState, useRef, useEffect, useReducer } from 'react';
import ReactDom from 'react-dom';
import Select, { components } from 'react-select';
import Async, { useAsync } from 'react-select/async';
import AsyncSelect from 'react-select/async';
import { createRoot } from 'react-dom/client';
import Results from './Results';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import "./Fit.css"

import { fetchPadX, setPadX } from "./Setters";
import { fetchPadY, setPadY } from "./Setters";
import { fetchPadZ, setPadZ } from "./Setters";
import { fetchGripZ, setGripZ } from "./Setters";
import { fetchExtLength, setExtLength } from "./Setters";
import { fetchTilt, setTilt } from "./Setters";
import { fetchToleranceXY, setToleranceXY } from "./Setters";
import { fetchToleranceZ, setToleranceZ } from "./Setters";

import { loadBrands, fetchBrands, setBrands } from "./Setters";
import { fetchModels, setModels } from "./Setters";
import { fetchSizes, setSizes } from "./Setters";
import { fetchBasebars, setBasebars } from "./Setters";
import { fetchBrackets, setBrackets } from "./Setters";
import { fetchArmrests, setArmrests } from "./Setters";
import { fetchExtensions, setExtensions } from "./Setters";
import { fetchStems, setStems } from "./Setters";
import { fetchSteererSpacers, setSteererSpacers } from "./Setters";

//import Calculate from "./Calculate";

import axios from "axios";

//import { fetchBrandData } from "./Setters";

export default function Fit() {

  const [ignored, forceUpdate] = useReducer(x => x+1, 0);
  
  const navigate = useNavigate();

  const [selectedBrand, setSelectedBrand] = useState("none")
  const [selectedModel, setSelectedModel] = useState("")
  const [selectedSize, setSelectedSize] = useState("")
  const [selectedBasebar, setSelectedBasebar] = useState("")
  const [selectedBracket, setSelectedBracket] = useState("")
  const [selectedArmrest, setSelectedArmrest] = useState("")
  const [selectedExtension, setSelectedExtension] = useState("")
  const [selectedStem, setSelectedStem] = useState("")
  const [selectedSteererSpacer, setSelectedSteererSpacer] = useState("")
  
  const newUser = sessionStorage.getItem("userid");
  const userid = JSON.parse( newUser ).id
  const username = JSON.parse( newUser ).payload

  axios.defaults.baseURL = "https://profile.greengecko.co.nz:5000"
	//axios.defaults.headers.common["Content-Type"] = "text/plain"
  axios.defaults.headers.common["Content-Type"] = "application/json"
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "https://profile.greengecko.co.nz"

  console.log ( "start", userid );
  console.log ( "start", username );
  console.log ( "sb", typeof selectedBrand, selectedBrand )
//alert ( this.getState() )

  if ( typeof selectedBrand === 'undefined' || selectedBrand == "none" ) {
  console.log ( "id", userid );
  const url = `/brands?payload=${userid}`;

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setBrands ( response.data );
	    console.log ( response.data )
	    console.log ( "brands loaded", fetchBrands() )
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  }

  function updateBrand (data) {
	  console.log ( "ud", data )
    sessionStorage.removeItem('model')
    sessionStorage.removeItem('size')
    sessionStorage.removeItem('basebar')
    sessionStorage.removeItem('bracket')
    sessionStorage.removeItem('armrest')
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/models?payload=${data}`;

    console.log ( "updateBrand", url )
      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {

	    setModels ( response.data );
	    //myModel()
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });

  };

  const updateModel = (val) => {
	console.log ( "updateModel" )
	console.log ( val )
    //setSelectedModel(event.value)
    sessionStorage.removeItem('size')
    sessionStorage.removeItem('basebar')
    sessionStorage.removeItem('bracket')
    sessionStorage.removeItem('armrest')
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/sizes?payload=${val.value}`;
    console.log ( url )
      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setSizes ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateSize = (val) => {
    setSelectedSize(val.value)
	console.log ( "updateSize" )
	console.log ( val )
    sessionStorage.removeItem('basebar')
    sessionStorage.removeItem('bracket')
    sessionStorage.removeItem('armrest')
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/basebars?payload=${val.value}`;
	  console.log ( url )

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setBasebars ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateBasebar = (event) => {
    setSelectedBasebar(event.value)
    sessionStorage.removeItem('bracket')
    sessionStorage.removeItem('armrest')
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/brackets?payload=${event.value}`;
	  //alert ( url )

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setBrackets ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateBracket = (event) => {
    setSelectedBracket(event.value)
    sessionStorage.removeItem('armrest')
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/armrests`;
	  //alert ( url )

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setArmrests ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateArmrest = (event) => {
    setSelectedArmrest(event.value)
    sessionStorage.removeItem('extension')
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
    const url = `/extensions?payload=${selectedBasebar}`;

	  //alert (url)
      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setExtensions ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateExtension = (event) => {
    setSelectedExtension(event.value)
    sessionStorage.removeItem('stem')
    sessionStorage.removeItem('steererspacer')
	console.log ( "sm", selectedModel )
    //const url = `/stems?payload=${event.value}`;
    const url = `/stems?payload=48`;

    console.log ( "stem", url )

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setStems ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
  };

  const updateStem = (event) => {
    setSelectedStem(event.value)
    sessionStorage.removeItem('steererspacer')
  };

  const updateSteererSpacer = (event) => {
    setSelectedSteererSpacer(event.value)
  };

  const resetBrands = (event) => {
    if ( fetchBrands() == null ) {
  	const url = `/brands?payload=${userid}`;

      	axios({ method: 'get', url: url, })
        .then(function (response) {
	  if (response.data) {
	    setBrands ( response.data );
            return ( fetchBrands() );
	  }
        })
        .catch(function (error) { console.log(error, "error"); });

	console.log ( "resetbrands", fetchBrands() );
    }
  };

  const resetModels = (event) => {
      const url = `/models?payload=${selectedBrand}`;

      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
	  if (response.data) {
	    setModels ( response.data );
	  }
        })
        .catch(function (error) {
          console.log(error, "error");
        });

	  console.log ( "resetmod" );
  };

  const myBrandAsync = () => (
	  <AsyncSelect
	    defaultOptions
	    cacheOptions
	    value={selectedBrand}
	    placeholder="Select Brand"
	    //loadOptions={loadBrands( (options) => options)}
	    loadOptions={JSON.parse(sessionStorage.getItem('brands'))}
	    onChange={(e) => handleSelectedBrand(e)}
	    //onFocus={loadBrands()}
	  />
  )

	    
  useEffect(() => {
	  console.log ("Done brand", selectedBrand)
  }, [selectedBrand]);

  const options = [];

  const handleSelectedBrand = (e) => {
	  setSelectedBrand(e.value)
	  //alert ( "ha", selectedBrand )
	  //alert ( e.value, selectedBrand )
  	  const newOptions = sessionStorage.getItem("brands");
  	  options = JSON.parse( newOptions )
	  updateBrand(e.value)

  }

  const [allBrands, setAllBrands] = useState([])

  const handlelocalBrands = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedBrand(obj);
	sessionStorage.removeItem("result")
	//sessionStorage.removeItem("model")
	//const results = document.getElementById("results")
	//ReactDom.render(<Results />, document.getElementById("results") )
	//console.log ( "sb", selectedBrand )
  };
  const localBrands = () => {
	  const json = JSON.parse(sessionStorage.getItem("userid") )
 	  const url = `/brands?payload=${json.id}`
	  return axios
	      .get(url)
	      .then((res) => setAllBrands(res.data));
  }

  const myBrand= () => (
	  <Select
	    name="myBrand"
	    placeholder="Select Brand"
	    cacheOptions
	    defaultOptions
	    options={allBrands}
	    //value={selectAllBrands}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalBrands}
	    onFocus={() => localBrands()}
	  />
  )

  const [allModels, setAllModels] = useState([])

  const handlelocalModels = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedModel(obj);
	console.log ( "sb", selectedModel )
  };
  const localModels = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "mb", selectedBrand.value )
 	  const url = `/models?payload=${selectedBrand.value}`
 	  //const url = `/models?payload=CER`
	  return axios
	      .get(url)
	      .then((res) => setAllModels(res.data));
  }

  const myModel= () => (
	  <Select
	    name="myModel"
	    placeholder="Select Model"
	    cacheOptions
	    defaultOptions
	    options={allModels}
	    //value={selectAllModels}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalModels}
	    onFocus={() => localModels()}
	  />
  )

  const [allSizes, setAllSizes] = useState([])

  const handlelocalSizes = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedSize(obj);
	console.log ( "sb", selectedSize )
  };
  const localSizes = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "sz", selectedModel.value )
 	  const url = `/sizes?payload=${selectedModel.value}`
	  return axios
	      .get(url)
	      .then((res) => setAllSizes(res.data));
  }

  const mySize = () => (
	  <Select
	    name="mySize"
	    placeholder="Select Size"
	    cacheOptions
	    defaultOptions
	    options={allSizes}
	    //value={selectAllSizes}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalSizes}
	    onFocus={() => localSizes()}
	  />
  )
	
  const [allBasebars, setAllBasebars] = useState([])

  const handlelocalBasebars = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedBasebar(obj);
	console.log ( "sb", selectedBasebar )
  };
  const localBasebars = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "sz", selectedModel.value )
 	  const url = `/basebars?payload=${selectedModel.value}`
	  return axios
	      .get(url)
	      .then((res) => setAllBasebars(res.data));
  }

  const myBasebar = () => (
	  <Select
	    name="myBasebar"
	    placeholder="Select Basebar"
	    cacheOptions
	    defaultOptions
	    options={allBasebars}
	    //value={selectAllBasebars}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalBasebars}
	    onFocus={() => localBasebars()}
	  />
  )
	
  const [allBrackets, setAllBrackets] = useState([])

  const handlelocalBrackets = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedBracket(obj);
	console.log ( "sb", selectedBracket )
  };
  const localBrackets = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "sz", selectedModel.value )
 	  const url = `/brackets?payload=${selectedBasebar.value}`
	  return axios
	      .get(url)
	      .then((res) => setAllBrackets(res.data));
  }

  const myBracket = () => (
	  <Select
	    name="myBracket"
	    placeholder="Select Bracket"
	    cacheOptions
	    defaultOptions
	    options={allBrackets}
	    //value={selectAllBrackets}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalBrackets}
	    onFocus={() => localBrackets()}
	  />
  )

  const [allArmrests, setAllArmrests] = useState([])

  const handlelocalArmrests = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedArmrest(obj);
	console.log ( "sb", selectedArmrest )
  };
  const localArmrests = () => {
	  console.log ( "sz", selectedModel.value )
 	  const url = `/armrests`
	  return axios
	      .get(url)
	      .then((res) => setAllArmrests(res.data));
  }

  const myArmrest = () => (
	  <Select
	    name="myArmrest"
	    placeholder="Select Armrest"
	    cacheOptions
	    defaultOptions
	    options={allArmrests}
	    //value={selectAllArmrests}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalArmrests}
	    onFocus={() => localArmrests()}
	  />
  )

  const [allExtensions, setAllExtensions] = useState([])

  const handlelocalExtensions = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedExtension(obj);
	console.log ( "sb", selectedExtension )
  };
  const localExtensions = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "sz", selectedModel.value )
 	  const url = `/extensions?payload=${selectedBracket.value}`
	  return axios
	      .get(url)
	      .then((res) => setAllExtensions(res.data));
  }

  const myExtension = () => (
	  <Select
	    name="myExtension"
	    placeholder="Select Extension"
	    cacheOptions
	    defaultOptions
	    options={allExtensions}
	    //value={selectAllExtensions}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalExtensions}
	    onFocus={() => localExtensions()}
	  />
  )
	
  const [allStems, setAllStems] = useState([])

  const handlelocalStems = (obj) => {
	console.log ( "hlb", obj );
    	setSelectedStem(obj);
	console.log ( "sb", selectedStem )
  };
  const localStems = () => {
	  //const json = JSON.parse(selectedBrand)
	  console.log ( "sz", selectedModel.value )
 	  const url = `/stems?payload=${selectedModel.value}`
	  return axios
	      .get(url)
	      .then((res) => setAllStems(res.data));
  }

  const myStem = () => (
	  <Select
	    name="myStem"
	    placeholder="Select Stem"
	    cacheOptions
	    defaultOptions
	    options={allStems}
	    //value={selectAllStems}
	    getOptionLabel = {(option) => option.label}
	    getOptionValue = {(option) => option.value}
	    onChange={handlelocalStems}
	    onFocus={() => localStems()}
	  />
  )

  //const newModels = () => {
	  //const inputRef = React.useRef();
	  //const [newOpts, setOpts] = React.useState([]);
	  //const addTo = React.useCallback((e) => {
		  //set
  //}
	//
  useEffect(() => {
	  console.log ("Done model", selectedModel)
  }, [selectedModel]);
	
const handleSelectedModel = async (input, callback) => {
    setloadModels({
      optionsLoaded: true,
      options: sessionStorage.getItem('models'),
      isLoading: false
    });
	if ( typeof input != "undefined" ) {
		console.log ( "selected models" )
		console.log ( "input", input )
		updateModel(input)
	}
  };
   
const [loadModels, setloadModels] = useState({
    optionsLoaded: false,
    options: [],
    isLoading: false
  });

const maybeloadModels = () => {
    if (!loadModels.optionsLoaded) {
      setloadModels({ isLoading: true });
	    console.log ( "mlm", selectedModel )
      handleSelectedModel();
    }
  };

  //const myModel = () => (
	  //<Select
	    //name="myModel"
	    //placeholder="Select Model"
	    //cachedOptions={false}
	    //defaultOptions={true}
	    //autoload={false}
	    //isLoading={loadModels.isLoading}
	    //options={JSON.parse(sessionStorage.getItem('models'))}
	    //onFocus={maybeloadModels}
	    ////onChange={(e) => handleSelectedModel(e)}
	    //onChange={handleSelectedModel}
	  ///>
  //)

  useEffect(() => {
	  console.log ("Done size", selectedSize)
  }, [selectedSize]);
	
const handleSelectedSize = async (input, callback) => {
    setloadSizes({
      optionsLoaded: true,
      options: sessionStorage.getItem('basebars'),
      isLoading: false
    });
	if ( typeof input != "undefined" ) {
		console.log ( "selected sizes" )
		console.log ( "input", input )
		updateSize(input)
	}
  };
   
const [loadSizes, setloadSizes] = useState({
    optionsLoaded: false,
    options: [],
    isLoading: false
  });

const maybeloadSizes = () => {
    if (!loadSizes.optionsLoaded) {
      setloadSizes({ isLoading: true });
	    console.log ( "mls", selectedSize )
      //handleSelectedModel();
    }
  };

  //const mySize = () => (
	  //<Select
	    //name="mySize"
	    //placeholder="Select Size"
	    //cachedOptions={false}
	    //defaultOptions={true}
	    //autoload={false}
	    //isLoading={loadModels.isLoading}
	    //options={JSON.parse(sessionStorage.getItem('sizes'))}
	    //onFocus={maybeloadSizes}
	    ////onChange={(e) => handleSelectedModel(e)}
	    //onChange={handleSelectedSize}
	  ///>
  //)

  useEffect(() => {
	  console.log ("Done basebar", selectedBasebar)
  }, [selectedBasebar]);
	
const handleSelectedBasebar = async (input, callback) => {
    setloadBasebars({
      optionsLoaded: true,
      options: sessionStorage.getItem('models'),
      isLoading: false
    });
	if ( typeof input != "undefined" ) {
		console.log ( "selected sizes" )
		console.log ( "input", input )
		updateBasebar(input)
	}
  };
   
const [loadBasebars, setloadBasebars] = useState({
    optionsLoaded: false,
    options: [],
    isLoading: false
  });

const maybeloadBasebars = () => {
    if (!loadBasebars.optionsLoaded) {
      setloadBasebars({ isLoading: true });
	    console.log ( "mls", selectedBasebar )
      //handleSelectedModel();
    }
  };
  //const myBasebar = () => (
	  //<Select
	    //name="myBasebar"
	    //placeholder="Select Basebar"
	    //cachedOptions={false}
	    //defaultOptions={true}
	    //autoload={false}
	    //isLoading={loadBasebars.isLoading}
	    //options={JSON.parse(sessionStorage.getItem('basebars'))}
	    //onFocus={maybeloadBasebars}
	    ////onChange={(e) => handleSelectedModel(e)}
	    //onChange={handleSelectedBasebar}
	  ///>
  //)

  //const myBracket = () => (
	  //<Select
	    //name="myBracket"
	    //placeholder="Select Bracket"
	    //selectedValue={selectedBracket}
	    //options={fetchBrackets()} 
	    //onChange={(e) => updateBracket(e)}
	  ///>
  //)

  //const myArmrest = () => (
	  //<Select
	    //name="myArmrest"
	    //placeholder="Select Armrest"
	    //selectedValue={selectedArmrest}
	    //options={fetchArmrests()} 
	    //onChange={(e) => updateArmrest(e)}
	  ///>
  //)

  //const myExtension = () => (
	  //<Select
	    //name="myExtension"
	    //placeholder="Select Extension"
	    //selectedValue={selectedExtension}
	    //options={fetchExtensions()} 
	    //onChange={(e) => updateExtension(e)}
	  ///>
  //)

  //const myStem = () => (
	  //<Select
	    //name="myStem"
	    //placeholder="Select Stem"
	    //selectedValue={selectedStem}
	    //options={fetchStems()} 
	    //onChange={(e) => updateStem(e)}
	  ///>
  //)

  useEffect(() => {
	  console.log ("Done stem", selectedStem)
  }, [selectedStem]);
	
const handleSelectedStem = async (input, callback) => {
    setloadStems({
      optionsLoaded: true,
      options: sessionStorage.getItem('models'),
      isLoading: false
    });
	if ( typeof input != "undefined" ) {
		console.log ( "selected sizes" )
		console.log ( "input", input )
		updateStem(input)
	}
  };
   
const [loadStems, setloadStems] = useState({
    optionsLoaded: false,
    options: [],
    isLoading: false
  });

const maybeloadStems = () => {
    if (!loadStems.optionsLoaded) {
      setloadStems({ isLoading: true });
	    console.log ( "mls", selectedStem )
      //handleSelectedModel();
    }
  };
  //const myStem = () => (
	  //<Select
	    //name="myStem"
	    //placeholder="Select Stem"
	    //cachedOptions={false}
	    //defaultOptions={true}
	    //autoload={false}
	    //isLoading={loadStems.isLoading}
	    //options={JSON.parse(sessionStorage.getItem('stems'))}
	    //onFocus={maybeloadStems}
	    ////onChange={(e) => handleSelectedModel(e)}
	    //onChange={handleSelectedStem}
	  ///>
  //)

  const mySteererSpacer = () => (
	  <Select
	    placeholder="Select Steerer Spacer Length"
	    name="mySteererSpacer"
	    selectedValue={selectedSteererSpacer}
	    options={fetchSteererSpacers()} 
	    onChange={(e) => updateSteererSpacer(e)}
	  />
  )

  function showResults (info) {
	  console.log ( "showRes", sessionStorage.getItem("result") )
	  const results = document.getElementById("results")
	  ReactDom.render(<Results />, document.getElementById("results") )
	  sessionStorage.removeItem("result")
  }

    //frame_size: int
    //stem: int
    //basebar: int
    //steerer_spacers: int
    //pad_x: int
    //pad_y: int
    //pad_z: int
    //grip_z: int
    //ext_length: int
    //tilt: int
    //tolerance_xy: int
    //tolerance_z: int

  function myCalculate () {
	  const postData = {
	    //frame_brand: selectedBrand,
	    //frame_model: selectedModel,
	    frame_size: selectedSize,
	    stem: selectedStem,
	    basebar: selectedBasebar,
	    steerer_spacers: Number(fetchSteererSpacers()),
	    pad_x: Number(fetchPadX()),
	    pad_y: Number(fetchPadY()),
	    pad_z: Number(fetchPadZ()),
	    grip_z: Number(fetchGripZ()),
	    ext_length: Number(fetchExtLength()),
	    tilt: Number(fetchTilt()),
	    tolerance_xy: Number(fetchToleranceXY()),
	    tolerance_z: Number(fetchToleranceZ()),
	 }
    const url = `/search`;

	    console.log("frame_size", selectedSize.value)
	    console.log("stem", selectedStem.value)
	    console.log("steerer_spacers", Number(fetchSteererSpacers()))
	    console.log("basebar", selectedBasebar.value)
	    console.log("pad_x", Number(fetchPadX()))
	    console.log("pad_y", Number(fetchPadY()))
	    console.log("pad_z", Number(fetchPadZ()))
	    console.log("grip_z", Number(fetchGripZ()))
	    console.log("ext_length", Number(fetchExtLength()))
	    console.log("tilt", Number(fetchTilt()))
	    console.log("tolerance_xy", Number(fetchToleranceXY()))
	    console.log("tolerance_z", Number(fetchToleranceZ()))
      axios
        .post("/search", {

	    //postData
	    frame_size: selectedSize.value,
	    stem: selectedStem.value,
	    steerer_spacers: Number(fetchSteererSpacers()),
	    basebar: selectedBasebar.value,
	    pad_x: Number(fetchPadX()),
	    pad_y: Number(fetchPadY()),
	    pad_z: Number(fetchPadZ()),
	    grip_z: Number(fetchGripZ()),
	    ext_length: Number(fetchExtLength()),
	    tilt: Number(fetchTilt()),
	    tolerance_xy: Number(fetchToleranceXY()),
	    tolerance_z: Number(fetchToleranceZ()),
	})
        .then(function (response) {
	  if (response.data) {
		  //alert ( JSON.stringify(response.data) )
		  sessionStorage.setItem("result", JSON.stringify(response.data) )
		  {showResults()}
          }
	})
        .catch(function (error) {
          console.log(error, "error");
	  sessionStorage.removeItem("result")
	  //const results = document.getElementById("results")
	  //ReactDom.render(<Results />, document.getElementById("results") )
        });
  
  }


  return (
    <div className="fit" style={{ minHeight: 100, marginTop: 30, width: 200 }}>
     <div>
      <div style={{ marginTop: 30 }}>
        <h2>Fit</h2>
          <div>
            <form>
	      {console.log ( "mybrand" )}
	      <div className="myBrand">
  	          {myBrand()}
	      </div>
	      <div className="myModel">
  	          {myModel()}
	      </div>
	      <div className="mySize">
  	          {mySize()}
	      </div>
	      <div className="myBasebar">
  	          {myBasebar()}
	      </div>
	      <div className="myBracket">
  	          {myBracket()}
	      </div>
	      <div className="myArmrest">
  	          {myArmrest()}
	      </div>
	      <div className="myExtension">
  	          {myExtension()}
	      </div>
	      <div className="myStem">
  	          {myStem()}
	      </div>
	      <div className="mySteererSpacer">
                <input type="number" placeholder="Steerer Spacers mm" onChange={(e) => setSteererSpacers(e.target.value)} />
	      </div>
            </form>
	  </div>
      </div>
     </div>
	  <button onClick={myCalculate}>calculate</button>
    </div>
  );
}
