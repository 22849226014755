// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.target {
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
}

input {
	margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Target.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":["div.target {\n\tdisplay: inline-block;\n\tvertical-align: top;\n\tmargin-left: 20px;\n}\n\ninput {\n\tmargin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
