// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    line-height: 1.5;
    min-height: 100vh;
    background: #f3f3f3;
    flex-direction: column;
    margin: 0;
}

.main {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    transition: transform 0.2s;
    width: 500px;
    text-align: center;
}

h1 {
    color: black;
}

label {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: left;
    color: #555;
    font-weight: bold;
}


input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: medium;
}

button {
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: #000000;
    width: 100%;
    font-size: 16px;
}

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;;AAGA;IACI,cAAc;IACd,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["body {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-family: sans-serif;\n    line-height: 1.5;\n    min-height: 100vh;\n    background: #f3f3f3;\n    flex-direction: column;\n    margin: 0;\n}\n\n.main {\n    background-color: #fff;\n    border-radius: 15px;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n    padding: 10px 20px;\n    transition: transform 0.2s;\n    width: 500px;\n    text-align: center;\n}\n\nh1 {\n    color: black;\n}\n\nlabel {\n    display: block;\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 5px;\n    text-align: left;\n    color: #555;\n    font-weight: bold;\n}\n\n\ninput {\n    display: block;\n    width: 100%;\n    margin-bottom: 15px;\n    padding: 10px;\n    box-sizing: border-box;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    font-size: medium;\n}\n\nbutton {\n    padding: 15px;\n    border-radius: 10px;\n    margin-top: 15px;\n    margin-bottom: 15px;\n    border: none;\n    color: white;\n    cursor: pointer;\n    background-color: #000000;\n    width: 100%;\n    font-size: 16px;\n}\n\n.wrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
