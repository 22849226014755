// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.results {
	vertical-align: top;
	margin-left: 20px;
	width: 300px;
}

input {
	margin-bottom: 5px;
}

p.results {
	display: flex;
	text-align: left;
	background: white;
	padding: 5px 5px 5px 5px;
	border-radius: 10px;
	background-color: f3f3f3;
}
`, "",{"version":3,"sources":["webpack://./src/Results.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,gBAAgB;CAChB,iBAAiB;CACjB,wBAAwB;CACxB,mBAAmB;CACnB,wBAAwB;AACzB","sourcesContent":["div.results {\n\tvertical-align: top;\n\tmargin-left: 20px;\n\twidth: 300px;\n}\n\ninput {\n\tmargin-bottom: 5px;\n}\n\np.results {\n\tdisplay: flex;\n\ttext-align: left;\n\tbackground: white;\n\tpadding: 5px 5px 5px 5px;\n\tborder-radius: 10px;\n\tbackground-color: f3f3f3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
