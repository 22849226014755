import { useNavigate } from "react-router";
import "./Target.css"
import { fetchPadX, setPadX } from "./Setters";
import { fetchPadY, setPadY } from "./Setters";
import { fetchPadZ, setPadZ } from "./Setters";
import { fetchGripZ, setGripZ } from "./Setters";
import { fetchExtLength, setExtLength } from "./Setters";
import { fetchTilt, setTilt } from "./Setters";
import { fetchToleranceXY, setToleranceXY } from "./Setters";
import { fetchToleranceZ, setToleranceZ } from "./Setters";

import { useState } from "react";
import axios from "axios";

export default function Target() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  return (
    <div className="target" style={{ minHeight: 100, marginTop: 30, width: 200 }}>
      <div style={{ marginTop: 30 }}>
        <h2>Target Position</h2>
          <div>
            <form>
              <input
	        placeholder="Pad X mm"
                type="number"
                onChange={(e) => setPadX(e.target.value)}
              />
              <input
	        placeholder="Pad Y mm"
                type="number"
                onChange={(e) => setPadY(e.target.value)}
              />
              <input
	        placeholder="Pad Z mm"
                type="number"
                onChange={(e) => setPadZ(e.target.value)}
              />
              <input
	        placeholder="Grip Z mm"
                type="number"
                onChange={(e) => setGripZ(e.target.value)}
              />
              <input
                type="number"
	        placeholder="Extension Len mm"
                onChange={(e) => setExtLength(e.target.value)}
              />
              <input
	        placeholder="Tilt degrees"
                type="number"
                onChange={(e) => setTilt(e.target.value)}
              />
            </form>
	  </div>
      <h2>Tolerances</h2>
          <div>
            <form>
              <input
	        placeholder="XY mm"
                type="number"
                onChange={(e) => setToleranceXY(e.target.value)}
              />
              <input
	        placeholder="Z mm"
                type="number"
                onChange={(e) => setToleranceZ(e.target.value)}
              />
	   </form>
          </div>
      </div>
    </div>
  );
}
