import { useNavigate } from "react-router";

import "./Profile"
import Target from "./Target"
import Fit from "./Fit"
import Results from "./Results"

import './Profile.css'

export default function Profile() {
  const navigate = useNavigate();

  const signOut = () => {
    sessionStorage.removeItem("userid");
    sessionStorage.clear();
    navigate("/");
  };

  const newUser = sessionStorage.getItem("userid");
  const userid = JSON.parse( newUser ).id
  const username = JSON.parse( newUser ).payload


  return (
    <>
      <div style={{ marginTop: 20, minHeight: 700 }}>
        <h1>Fitting page</h1>
        <p>Hello there {username}, welcome to your profile page</p>
	<div className="profile">
	    <Target />
	    <Fit />
	    <Results />
	</div>
        <button onClick={signOut}>sign out</button>
      </div>
    </>
  );
}

