// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    line-height: 1.5;
    min-height: 100vh;
    background: #f3f3f3;
    flex-direction: column;
    margin: 0;
}

div.fit {
	display: inline-block;
	margin-top: 30px;
	margin-left: 30px;
	vertical-align: top;
}

div.visible {
	display: block;
}

div.hidden {
	display: none;
}

div {
	bargin-bottom: 5px;
}

div.right {
	float: right;
}

div.myBrand, div.myModel, div.mySize, div.myBasebar, div.myBracket, div.myArmrest, div.myExtension, div.myStem, div.mySteererSpacer {
	margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Fit.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;CACC,qBAAqB;CACrB,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":["body {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-family: sans-serif;\n    line-height: 1.5;\n    min-height: 100vh;\n    background: #f3f3f3;\n    flex-direction: column;\n    margin: 0;\n}\n\ndiv.fit {\n\tdisplay: inline-block;\n\tmargin-top: 30px;\n\tmargin-left: 30px;\n\tvertical-align: top;\n}\n\ndiv.visible {\n\tdisplay: block;\n}\n\ndiv.hidden {\n\tdisplay: none;\n}\n\ndiv {\n\tbargin-bottom: 5px;\n}\n\ndiv.right {\n\tfloat: right;\n}\n\ndiv.myBrand, div.myModel, div.mySize, div.myBasebar, div.myBracket, div.myArmrest, div.myExtension, div.myStem, div.mySteererSpacer {\n\tmargin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
