import { useLocation,Navigate } from "react-router-dom"

import axios from "axios";

export const loadBrands = () => {
//export const loadBrands  = async ( inputText: string, callback: any ): Promise<OptionTypeBase[]> => {
	const json = JSON.parse(sessionStorage.getItem("userid") )
 	const url = `/brands?payload=${json.id}`
	
	//console.log ( "lb",url )
      	axios.get( url )
        	.then((response) => { 
			if (response.data) { 
				setBrands ( response.data ); 
				//console.log ("returning load", sessionStorage.getItem('brands'));
    				return JSON.parse(sessionStorage.getItem('brands'))
				//return fetchBrands()
			} 
		} )
        	.catch((error) => { 
			console.log(error, "error"); 
		});
}

export const setBrands = (datum) =>{

    sessionStorage.setItem('brands',JSON.stringify( datum))
}

export const fetchBrands = (datum) =>{

    //const Brands = JSON.parse (sessionStorage.getItem('brands'));
	//console.log (  Brands )
	//console.log ( sessionStorage.getItem('brands') )
    return sessionStorage.getItem('brands')
}



export const setPadX = (datum) =>{

    sessionStorage.setItem('padx', datum)// make up your own token
}

export const fetchPadX = (datum) =>{

    return sessionStorage.getItem('padx');
}

export const setPadY = (datum) =>{

    sessionStorage.setItem('pady', datum)// make up your own token
}

export const fetchPadY = (datum) =>{

    return sessionStorage.getItem('pady');
}

export const setPadZ = (datum) =>{

    sessionStorage.setItem('padz', datum)// make up your own token
}

export const fetchPadZ = (datum) =>{

    return sessionStorage.getItem('padz');
}

export const setGripZ = (datum) =>{

    sessionStorage.setItem('gripz', datum)// make up your own token
}

export const fetchGripZ = (datum) =>{

    return sessionStorage.getItem('gripz');
}

export const setExtLength = (datum) =>{

    sessionStorage.setItem('extlength', datum)// make up your own token
}

export const fetchExtLength = (datum) =>{

    return sessionStorage.getItem('extlength');
}

export const setTilt = (datum) =>{

    sessionStorage.setItem('tilt', datum)// make up your own token
}

export const fetchTilt = (datum) =>{

    return sessionStorage.getItem('tilt');
}

export const setToleranceXY = (datum) =>{

    sessionStorage.setItem('tolerancexy', datum)// make up your own token
}

export const fetchToleranceXY = (datum) =>{

    return sessionStorage.getItem('tolerancexy');
}

export const setToleranceZ = (datum) =>{

    sessionStorage.setItem('tolerancez', datum)// make up your own token
}

export const fetchToleranceZ = (datum) =>{

    return sessionStorage.getItem('tolerancez');
}

export const setModels = (datum) =>{

    sessionStorage.setItem('models',JSON.stringify( datum))
    console.log ( "setModels", sessionStorage.getItem('models') )
}

export const fetchModels = (datum) =>{

    const Models = JSON.parse (sessionStorage.getItem('models'));
	console.log ( Models )
    return Models;
}

export const setSizes = (datum) =>{
    sessionStorage.setItem('sizes',JSON.stringify( datum))
    console.log ( "setsizes", sessionStorage.getItem ( 'sizes') )
}

export const fetchSizes = (datum) =>{
    return JSON.parse (sessionStorage.getItem('sizes'));
}

export const setBasebars = (datum) =>{
    sessionStorage.setItem('basebars',JSON.stringify( datum))
    console.log ( "sbb", sessionStorage.getItem('basebars' ) )
}

export const fetchBasebars = (datum) =>{
    //alert ( datum )
    return JSON.parse (sessionStorage.getItem('basebars'));
}

export const setBrackets = (datum) =>{
    sessionStorage.setItem('brackets',JSON.stringify( datum))
}

export const fetchBrackets = (datum) =>{
    return JSON.parse (sessionStorage.getItem('brackets'));
}

export const setArmrests = (datum) =>{
    sessionStorage.setItem('armrests',JSON.stringify( datum))
}

export const fetchArmrests = (datum) =>{
    return JSON.parse (sessionStorage.getItem('armrests'));
}

export const setExtensions = (datum) =>{
    sessionStorage.setItem('extensions',JSON.stringify( datum))
}

export const fetchExtensions = (datum) =>{
    return JSON.parse (sessionStorage.getItem('extensions'));
}

export const setStems = (datum) =>{
    sessionStorage.setItem('stems',JSON.stringify( datum))
}

export const fetchStems = (datum) =>{
    return JSON.parse (sessionStorage.getItem('stems'));
}

export const setSteererSpacers = (datum) =>{
    sessionStorage.setItem('steererspacers', datum)
}

export const fetchSteererSpacers = (datum) =>{
    return sessionStorage.getItem('steererspacers');
}
