import "./Results.css"

export default function Results(data) {

  console.log ("Res: ",sessionStorage.getItem("result") )
  if ( sessionStorage.hasOwnProperty("result" ) === false ) {
  return (
    <div id="results" className="results" style={{ minHeight: 100, marginTop: 30, width: 200 }}>
      </div>
  )
  }
  const json =  JSON.parse ( sessionStorage.getItem("result") )

  if ( json.Status == "Failure" )
    return (
      <div style={{ marginTop: 30 }}>
        <h2>Results</h2>
          <div>
	   <p class="results">Sorry, no matches were found within the tolerances specified</p>
          </div>
        </div>
    )
	//alert ( sessionStorage.getItem("result") )

  return (
    <div id="results" className="results" style={{ minHeight: 100, marginTop: 30, width: 300 }}>
      <div style={{ marginTop: 30 }}>
        <h2>Results</h2>
          <div>
	  <p class="results">Frame: {json.Model}</p>
	  <p class="results">Stem: {json.Stem}</p>
	  <p class="results">BaseBar: {json.Basebar}</p>
	  <p class="results">Aerobar: {json.Aerobar}</p>
	  <p class="results">Tilt: {json.Tilt}</p>
	  <p class="results">Pad spacers/row/col: {json.PadSpacers}/{json.PadRow}/{json.PadCol}</p>
	  <p class="results">Description: {json.Description}</p>
	  <p class="results">Accuracy: {json.Accuracy}</p>
	  <p class="results">Pad X/Y: {json.PadX}/{json.PadY}</p>
	  <p class="results">Front/Rear bolt: {json.FrontBolt}/{json.RearBolt}</p>
	  <p class="results">Frame X/Y: {json.FrameX}/{json.FrameY}</p>
	  <p class="results">Bar X/Y: {json.BarX}/{json.BarY}</p>
          </div>
      </div>
    </div>
  );
}
